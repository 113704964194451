import './styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import DropdownComponent from 'Components/DropdownComponent'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import NoData from 'Assets/Images/NoData.jpg'
import { popupIcon } from 'Assets/Icons'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import { commonMethods } from 'Utils/commonMethods'
import { getLocalLoggedInStatus, getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import CreateAccountInfoModal from 'Components/UIComponent/CreateAccountInfoModal'
import { ROUTE_STRINGS } from 'Utils/Constants'
import MetaTagsComponent from 'Components/MetaTagsComponent'
import AccordionPopUp from './Components/AccordianPopUp'

const GlobalRawCoffeePrices = ({ mockGlobalRawCoffeePriceData, mockCountryData }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { setLoading } = useContext(CoffeeWebContext)
  const { checkForUserAccess } = commonMethods
  const userDetails = getLocalUserDetails()
  const isUserLoggedIn = getLocalLoggedInStatus()
  const { sub: { subType } = {} } = userDetails || {}

  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''

  if (userDetails?.id && ROUTE_STRINGS.globalrawcoffeeprices !== fullPath) {
    history.replace({ pathname: ROUTE_STRINGS.globalrawcoffeeprices })
  }

  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const getButtonAndInfo = () => {
    if (!userDetails) {
      return {
        buttonText: t('LOGIN_AND_CREATE_ACCOUNT'),
        infoMessage: t('TO_ACCESS_INFO_GLOBAL_DIFFERENTIAL'),
        infoMessage2: t('TO_ACCESS_GLOBAL_DIFFERENTIAL_FEATURE'),
        className: 'login-button',
        navigationPath: ROUTE_STRINGS.login,
        state: {}
      }
    }
    if (subType === 1) {
      return {
        buttonText: t('BUY_SUBSCRIPTION'),
        infoMessage: t('THIS_DATA_IS_AVAILABLE_EXCLUSIVELY_FOR_GOLD_AND_PLATINUM_USERS'),
        className: 'buy-subscription-button',
        navigationPath: ROUTE_STRINGS.subscription,
        state: { subscriptionId: 3 }
      }
    }
    if (subType === 2) {
      return {
        buttonText: t('UPGRADE'),
        infoMessage: t('TO_ACCESS_GLOBAL_DIFFERENTIAL_FEATURE'),
        className: 'buy-subscription-button',
        navigationPath: ROUTE_STRINGS.subscription,
        state: { subscriptionId: 3 }
      }
    }

    return {
      buttonText: t('LOGIN_AND_CREATE_ACCOUNT'),
      infoMessage: t('TO_ACCESS_INFO_GLOBAL_DIFFERENTIAL'),
      className: 'login-button',
      navigationPath: ROUTE_STRINGS.login,
      state: {}
    }
  }

  const { buttonText, infoMessage, className, navigationPath, state } = getButtonAndInfo()

  const [globalRawCoffeePrice, setGlobalRawCoffeePrice] = useState([])
  const [noDataFound, setNoDataFound] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [countryList, setCountryList] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)
  const [selectedQualityForHistoryData, setSelectedQualityForHistoryData] = useState([])
  const [createAccountPopup, setCreateAccountPopup] = useState(false)

  useEffect(() => {
    fetchGlobalRawCoffeePriceOrderByCurrency()

    // props for testing purpose
    if (mockGlobalRawCoffeePriceData) {
      setGlobalRawCoffeePrice(mockGlobalRawCoffeePriceData)
      setNoDataFound(true)
      setCreateAccountPopup(true)
    } else if (mockCountryData) {
      handleSelectCountry(mockCountryData)
    }
  }, [])

  const handleSelectCountry = (item) => {
    fetchGlobalRawCoffeePriceOrderByCurrency(item.id)
  }

  const fetchGlobalRawCoffeePriceOrderByCurrency = async (countryId = 102) => {
    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.getGlobalCoffeePriceForUsers(countryId)

      const globalCoffeePrice = data.returnLst?.globalCoffeePriceDTOs
      const coffeePriceCountryList = data.returnLst?.globalCoffeePriceCountryList

      if (globalCoffeePrice?.length > 0) {
        setGlobalRawCoffeePrice(globalCoffeePrice)
        setNoDataFound(false)
        if (!isUserLoggedIn || menuLock) {
          setCreateAccountPopup(true)
        }
        setCountryList(coffeePriceCountryList)
        if (countryId === 102) {
          setSelectedCountry(coffeePriceCountryList?.find((ele) => ele?.id === 102))
        }
      } else {
        setGlobalRawCoffeePrice([])
        setNoDataFound(true)
        setCreateAccountPopup(false)
      }
    } catch (error) {
      setNoDataFound(true)
      setCreateAccountPopup(false)
      setGlobalRawCoffeePrice([])
    } finally {
      setLoading(false)
      window.scrollTo({ top: 0 })
    }
  }

  const handleSelectQualityName = (...items) => {
    if (!menuLock && isUserLoggedIn) {
      setSelectedQualityForHistoryData(items)
    }
  }

  return (
    <div className="global-raw-coffee-price-container" data-testid="global-raw-coffee-price-main-container">
      {!userDetails && <MetaTagsComponent title={t('GLOBAL_RAW_COFFEE_PRICES_TITLE')} description={t('GLOBAL_RAW_COFFEE_PRICES_DESCRIPTION')} keywords={t('GLOBAL_RAW_COFFEE_PRICES_KEYWORDS')} url="https://coffeeweb.com/globalrawcoffeeprices" h1Content={t('GLOBAL_RAW_COFFEE_H1_CONTENT')} />}
      <div className="global-raw-coffee-price-headers" data-testid="component-header">
        <div className="title">{t('GLOBAL_COFFEE_PRICES')}</div>
        <div className="currency-dropdown">
          <span>{t('SELECT_ORIGIN')} :</span>
          <DropdownComponent isGlobalDifferential={true} handleSelectCountry={handleSelectCountry} countryList={countryList} currentItem={selectedCountry} setCurrentItem={setSelectedCountry} errorState={errorMessage} placeholderText={t('SELECT_COUNTRY')} width="100%" height="41px" testingId="global-raw-coffee-price-dropdown" />
        </div>
      </div>
      <div className="global-raw-coffee-price-wrapper" data-testid="global-raw-coffee-price-wrapper">
        {globalRawCoffeePrice?.map((item, index) => (
          <React.Fragment key={index}>
            {item.cropyear?.map((ele, cropYearIndex) => (
              <div key={cropYearIndex}>
                <>
                  <div className="crop-year-main-header">
                    <div className="country-and-crop-year">
                      <div className="country-with-flag">
                        <img src={item.flagUrl} alt="Flag" className="flag-icon" title="country-flag-images-in-mobile-view" /> {item?.country}
                      </div>
                    </div>
                    <div className="coffee-labeling">
                      {item?.country} {t('COFFEE')}
                    </div>
                  </div>

                  {ele?.globalCoffeePriceDTOs.map((qualityItem, childIndex) => (
                    <div className="global-raw-coffee-price-table" key={childIndex}>
                      <table className="each-table" key={index}>
                        <thead className="global-raw-coffee-price-table-header" data-testid="table-header">
                          <tr className="crop-year-th">
                            <th colSpan={5}>
                              <div className="crop-year-div">
                                <div>
                                  {t('CROP_YEAR')} : {ele?.cropyear}
                                </div>
                                <div className="updated-on-labeling">
                                  {t('UPDATED_ON')} : {qualityItem.upDatedDate}
                                </div>
                                <div className="forex-type-value">
                                  {qualityItem.forexType} : {qualityItem.forexValue}
                                </div>
                              </div>
                            </th>
                          </tr>
                          <tr className="top-first-th-row">
                            <th className="sl-no-th" data-testid="sl-number-th">
                              {childIndex + 1}
                            </th>
                            <th className="quality-th">{t('QUALITY')}</th>
                            <th className="terminal-th" colSpan={2}>
                              {qualityItem.terminal} : {qualityItem.terminalLevel}
                            </th>
                            <th className="remarks-th">{t('REMARKS')}</th>
                          </tr>
                          <tr>
                            <th>{t('SL')}</th>
                            <th className="quality-group-name-heading">{qualityItem?.qualityGroupName}</th>
                            <th className="between-heading" colSpan={2}>
                              {t('BETWEEN')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="global-raw-coffee-price-table-body" style={checkForUserAccess(menuLock)} data-testid="table-body">
                          {qualityItem?.globalCoffeePriceData.map((element, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className={`quality-name ${element?.rangeStart >= element?.previousRangeStart && element?.rangeEnd >= element?.previousRangeEnd ? 'high-value' : 'low-value'}`} onClick={() => handleSelectQualityName(selectedCountry?.id, item?.country, ele?.cropyear, element?.qualityName, ele.globalCoffeePriceDTOs[0].forexType, item?.flagUrl, element?.remarks, qualityItem.forexValue)} data-testid="quality-name">
                                <div className="quality-name-popup-icon">
                                  {element?.qualityName}
                                  <img className="popup-icon" src={popupIcon} alt="popup-icon" title="global-history-popup-icon" />
                                </div>
                              </td>
                              <td className={`between ${element?.rangeStart >= element?.previousRangeStart ? 'high-value' : 'low-value'}`}>{element?.rangeStart}</td>
                              <td className={`between ${element?.rangeEnd >= element?.previousRangeEnd ? 'high-value' : 'low-value'}`}>{element?.rangeEnd}</td>
                              <td>{element?.remarks}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="end-divider-wrapper">
                        <div className="end-divider-dots"></div>
                        <div className="end-divider-line"></div>
                        <div className="end-divider-dots"></div>
                      </div>
                    </div>
                  ))}
                </>
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
      {noDataFound && (
        <div className="no-data-found" data-testid="no-data-found">
          <img src={NoData} alt="NoDataFound" className="no-data-found-image" title="no-data-image-in-global-raw-coffee-price" />
          <div className="no-data-text"> {t('NO_DATA_FOUND_FOR_THIS_COUNTRY')}</div>
        </div>
      )}
      {createAccountPopup && (
        <div className="raw-coffee-price-create-account-info-modal-popup" data-testid="info-modal">
          <CreateAccountInfoModal buttonText={buttonText} infoMessage={infoMessage} className={className} navigationPath={navigationPath} state={state} testId="create-account-popup-in-differential" />
        </div>
      )}
      <AccordionPopUp selectedQualityForHistoryData={selectedQualityForHistoryData} setSelectedQualityForHistoryData={setSelectedQualityForHistoryData} setLoading={setLoading} />
    </div>
  )
}

export default GlobalRawCoffeePrices
