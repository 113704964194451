import './styles.scss'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import DropdownComponent from 'Components/DropdownComponent'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import { commonMethods } from 'Utils/commonMethods'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { NoData } from 'Assets/Images'

const USDACoffeeReports = ({ mockGetUSDAData, mockNoDataFound }) => {
  const { setLoading } = useContext(CoffeeWebContext)
  const { t } = useTranslation()

  const { checkForUserAccess, dateFormatBasedOnUser } = commonMethods

  const userDetails = getLocalUserDetails()
  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''

  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails.menus.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const [selectedCountry, setSelectedCountry] = useState({ id: 'all', name: 'All Countries', flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/usda-logo.svg' })
  const [getUSDAData, setGetUSDAData] = useState([])
  const [countriesList, setCountriesList] = useState([])
  const [expandedReports, setExpandedReports] = useState({})
  const [currentItem, setCurrentItem] = useState(null)
  const [dataLoading, setDataLoading] = useState(true)

  useEffect(() => {
    if (!getUSDAData?.length) {
      // Get Data on Page Mount
      getUSDACoffeeReport()
    } else {
      // Get Countries once Report Data is received
      getAllCountries()
    }
  }, [getUSDAData])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockGetUSDAData) {
      setGetUSDAData(mockGetUSDAData)
    } else if (mockNoDataFound) {
      setDataLoading(false)
    }
  }, [])

  const getUSDACoffeeReport = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getUSDACoffeeReport()

      if (response?.data?.returnLst?.length > 0) {
        setGetUSDAData(response?.data?.returnLst)
      } else {
        setDataLoading(false)
        setLoading(false)
      }
    } catch {
      setLoading(false)
      setDataLoading(false)
    }
  }

  const getAllCountries = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getAllCountries()

      if (getUSDAData.length > 0) {
        const countryIds = getUSDAData.map((item) => item.countryId)
        const filteredCountries = response?.data.filter((element) => countryIds.includes(element.id))

        const allCountriesOption = { id: 'all', nicename: 'All Countries', flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/usda-logo.svg' }

        setCurrentItem(allCountriesOption)
        setCountriesList([allCountriesOption, ...filteredCountries])
      }
    } catch (error) {
      setDataLoading(false)
      // console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDropDownChange = (event) => {
    setSelectedCountry(event)
  }

  const toggleExpand = (id) => {
    setExpandedReports((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }

  const filteredUSDAData = selectedCountry.id === 'all' ? getUSDAData : getUSDAData.filter((report) => report.countryId === selectedCountry.id)

  const getContentToShow = (isExpanded, content) => {
    if (isExpanded) {
      return content
    }

    return content.length > 1400 ? `${content.substring(0, 1300)}...` : content
  }

  return (
    <div className="usda-coffee-report-container" data-testid="usda-coffee-report-main-container">
      <div className="usda-heading-container" data-testid="heading-container">
        <div className="usda-country-dropdown" data-testid="country-dropdown">
          <DropdownComponent currentItem={currentItem} setCurrentItem={setCurrentItem} countryList={countriesList} handleSelectCountry={handleDropDownChange} testingId="coffee-report-dropdown" isUSDACoffeeReports={true} />
        </div>
        <div className="usda-heading">
          <p className="usda-heading-content">{t('USDA_COFFEE_REPORTS')}</p>
        </div>
      </div>
      <div className="usda-map-data" data-testid="data-container">
        {getUSDAData?.length > 0 && (
          <>
            {filteredUSDAData?.map((element, index) => {
              const isExpanded = expandedReports[element.idReport]
              const contentToShow = getContentToShow(isExpanded, element.content)

              return (
                <div key={index}>
                  <div className="usda-date-container" data-testid="usda-date-container">
                    <p className="usda-date" data-testid="usda-date">
                      {element?.isUpcoming ? 'Upcoming Report' : `Date: ${dateFormatBasedOnUser(element.publishedDate)}`}
                    </p>
                    <p className="usda-tags" data-testid="usda-tags">
                      {element?.tags}
                    </p>
                  </div>

                  {element?.isUpcoming ? (
                    <div className="usda-upcoming-container" style={checkForUserAccess(menuLock)}>
                      {t('WILL_BE_PUBLISHED_ON')} {dateFormatBasedOnUser(element.publishedDate)}
                    </div>
                  ) : (
                    <div className="usda-report-highlights-container" style={checkForUserAccess(menuLock)} data-testid="usda-report-highlights-container">
                      <p className="reports-title">{element.title}</p>
                      <div className="report-content">
                        <span>{contentToShow}</span>
                        {element.content.length > 1400 && (
                          <span>
                            <a href="#" onClick={() => toggleExpand(element.idReport)} className="read-more">
                              {!isExpanded && t('READ_MORE')}
                            </a>
                          </span>
                        )}
                      </div>

                      <div className="link-report-container">
                        <span>{t('LINK_TO_REPORT')}</span>
                        {userDetails && !menuLock ? (
                          <a href={element.reportLink} target="_blank" rel="noreferrer" className="report-link">
                            {element.tags}
                          </a>
                        ) : (
                          <div className="disabled-link" data-testid="report-link-tag">
                            {element.tags}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </>
        )}

        {getUSDAData?.length === 0 && !dataLoading && (
          <div className="no-data-found-container" data-testid="no-data-found-container">
            <img src={NoData} alt="NoDataFound" className="no-data-found-image" title="no-data-image" />
            <div className="no-data-found-text">{t('NO_REPORT_AVAILABLE')}</div>
          </div>
        )}
      </div>
      {/* For Aligning the Header */}
      <div></div>
    </div>
  )
}

export default USDACoffeeReports
