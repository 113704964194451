/* eslint-disable */
// https://translate.google.co.in/?sl=auto&tl=hi&text=Password%20Is%20Required&op=translate
const EN_LANGUAGES = {
  // AUTH Pages
  OK: 'OK',
  NO: 'No',
  YES: 'Yes',
  CONTINUE: 'Continue',
  CANCEL: 'Cancel',
  ALREADY_LOGGED_IN: 'You already logged in from another device.',
  CONFIRM_LOGOUT: 'Do you want to logout?',
  DONT_HAVE_AN_ACCOUNT: 'Don’t have an account?',
  CREATE_ACCOUNT_LABEL: 'CREATE ACCOUNT',
  CREATE_ACCOUNT_BUTTON_LABEL: 'Create Account',
  SUPPORT_LABEL: 'Support?',
  FORGET_PASSWORD_LABEL: 'Forgot password?',
  LOGIN: 'LOGIN',
  LOGIN_BUTTON: 'Login',
  SINGLE_DEVICE_CONTINUE_LOGIN: 'You already logged in from another device, would you like to continue from this device?',
  SINGLE_DEVICE_LOGOUT_NOTE: 'Note: If you continue from this device you will be logged out from other device!',
  INVALID_CREDENTIALS: 'Invalid Credentials!',
  PHONE_IS_REQUIRED: 'Phone Number is Required',
  EMAIL_IS_REQUIRED: 'Email Number is Required',
  INVALID_EMAIL_FORMAT: 'Invaid Email Format',
  PASSWORD_IS_REQUIRED: 'Password is Required',
  PHONE: 'Phone',
  PHONE_NUMBER: 'Phone Number',
  PHONE_OR_EMAIL: 'Phone or Email',
  ENTER_YOUR_EMAIL_ADDRESS_TO_RECEIVE_A_PASSWORD_RESET_LINK: 'Enter your email address to receive a reset password link',
  ENTER_YOUR_EMAIL_ADDRESS: 'Enter your Email address',
  FORGOT_YOUR_PASSWORD: 'Forgot Your Password',
  RESET_PASSWORD_LINK_HAS_BEEN_SENT_TO: 'Reset password link has been sent successfully to',
  EMAIL_NOT_REGISTERED: 'Email not Registered',
  RESET_PASSWORD_LINK_HAS_BEEN_SENT_TO: 'Reset password link has been sent to',
  CHECK_YOUR_EMAIL: 'Check Your Email',
  RETURN_TO_LOGIN: 'Return to Login',
  SAVE_PASSWORD: 'Save Password',
  TRY_OUT_LOGIN: 'Try out Login',
  SEND_RESET_LINK: 'Send Reset Link',
  PASSWORD_RESET_SUCCESSFULLY: 'Your password has been reset successfully.',
  NEW_PASSWORD_IS_REQUIRED: 'New Password is Required',
  CONFIRM_PASSWORD_IS_REQUIRED: 'Confirm Password is Required',
  PASSWORD_MISMATCH: 'Password Mismatch',
  LOGIN_NOW: 'Login Now',
  PASSWORD: 'Password',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
  LOGIN_HERE: 'Login Here',
  CONTINUE_WITH_GOOGLE: 'Continue with Google',
  COMING_SOON: 'Coming Soon...',
  SIGN_UP_WITH_GOOGLE: 'Sign up with Google',
  PASSWORD_FOR_COFFEEWEB: 'Password for CoffeeWeb',
  VERIFY_CREATE_ACCOUNT: 'Verify & Create Account',
  CLOSE: 'Close',
  RESEND_OTP: 'Resend OTP',
  OTP_IS_REQUIRED: 'OTP Is Required',
  VERIFY_MOBILE_NUMBER: 'Verify Mobile Number',
  ENTER_THE_OTP_SENT_TO: 'Enter the OTP sent to ',
  ENTER_ANOTHER_MOBILE_NUMBER: 'It looks like your Phone Number is already registered with Coffee Web. Please log in with your credentials or reset your password if you have forgotten it.',
  PHONE_NUMBER_ALREADY_EXISTS: 'Phone Number already Exists!',
  INVALID_PHONE_NUMBER: 'Invalid Phone Number',
  SELECT_COUNTRY: 'Select Country',
  COUNTRY: 'Country',
  NAME: 'Name',
  PLEASE_ENTER_ONLY_LETTERS: 'Please enter only letters',
  NAME_IS_REQUIRED: 'Name is Required',
  BY_SIGNING_UP_YOU_AGREE_TO_OUR: 'By signing up, you agree to our',
  TERMS_CONDITIONS: ' Terms & Conditions ',
  AND: 'and',
  PRIVACY_POLICY: ' Privacy Policy',
  VALID_PHONE_NUMBER_IS_REQUIRED: 'Phone Number Is Required ',
  SUBMIT: 'Submit',
  OTP: 'OTP',
  RESEND: 'Resend',
  CONFIRM_PASSWORD: 'Confirm Password',
  PASSWORD_CONFIRMATION_IS_REQUIRED: 'Password Confirmation Is Required',
  ENTER_OTP: 'Enter OTP',
  MOBILE_NUMBER_NOT_REGISTERED: 'Mobile Number Not Registered',
  ENTER_ANOTHER_NUMBER: 'Enter another Number!',
  ERROR: 'Error',
  SOMETHING_WENT_WRONG: 'An unknown error has been occurred while processing your request.',
  TRY_AGAIN_MESSAGE: 'Please try again in sometime.',
  PLEASE_FILL_ALL_THE_DETAILS: 'Please fill all the Details.',
  FAILURE: 'Failure !',
  FAILED_TO_SEND_OTP: 'Failed to Send OTP!',
  SUCCESS: 'Success',
  NEW_PASSWORD_UPDATED_SUCCESSFULLY: 'New Password Updated Successfully!',
  FAILED_TO_UPDATE_NEW_PASSWORD: 'Failed to Update New Password!',
  PLEASE_TRY_AFTER_SOMETIME: 'Please try after sometime...',
  DOWNLOAD_ON_THE: 'DOWNLOAD ON THE',
  APP_STORE: 'App Store',
  GET_IT_ON: 'GET IT ON',
  GOOGLE_PLAY: 'Google Play',
  VISIT_OUR_WEBSITE: 'Visit our Website',
  DOWNLOAD_ON_APP_STORE: 'Download on App Store',
  DOWNLOAD_ON_PLAY_STORE: 'Download on Play Store',
  YOU_WILL_NOT_BE_ABLE_TO_REVERT_THIS: 'This action is irreversible,',
  WORKBOOK_DELETE_CONFIRM: 'This will delete selected workbook.',
  LOGOUT: 'Logout',
  SETTINGS: 'Settings',
  LOGIN_AND_CREATE_ACCOUNT: 'Login/Create Account',
  PLEASE_ENTER_ONLY_NUMBERS: 'Please Enter Only Numbers',
  COUNTRY_LIST_DATA_NOT_AVAILABLE: 'Country List Not Available',
  SORRY_UNABLE_TO_LOGIN: 'Sorry, Unable To Login. Please Try Again Later',
  YOUR_PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY: 'Your password has been reset successfully.',
  FAILED_TO_SEND_RESET_PASSWORD_LINK: 'Failed to send reset password link',

  // SUPPORT PAGES
  SUPPORT: 'Support',
  ENTER_YOUR_NAME: 'Enter your Name',
  ENTER_ONLY_LETTERS: 'Enter only Letters',
  ENTER_YOUR_EMAIL: 'Enter your Email',
  ENTER_VALID_EMAIL: 'Enter valid Email',
  ENTER_YOUR_PHONE: 'Enter your Phone Number',
  SUBJECT: 'Subject',
  SUBJECT_IS_REQUIRED: 'Subject is Required',
  SUBJECT_ERROR_STATE: 'Subject should be at least 10 characters',
  MESSAGE: 'Message',
  MESSAGE_IS_REQUIRED: 'Message is Required',
  MESSAGE_ERROR_STATE: 'Message Should be at least 100 characters',
  I_AGREE_TO_THE: 'I agree to the ',
  AGREE_TERMS_AND_CONDITIONS: 'Agree to the Terms and Conditions is Required',
  WE_HAVE_RECEIVED_YOUR_EMAIL: 'We have received your email, we will get back.',
  FAILED_TO_SEND_MESSAGE: 'Failed to Send Message!',
  SUPPORT_TICKET_RAISED_SUCCESSFULLY: 'Support Ticket Raised Successfully!',
  A_CONFIRMATION_MAIL_IS_ON_THE_WAY: 'A confirmation mail is on the way.',
  SUPPORT_REQUEST_MESSAGE: 'We have successfully received your ticket and our dedicated team is already on it.Your issue is important to us, and we are committed to resolving it as quickly as possible.',
  RAISED_A_NEW_TICKET: 'Raised a New Ticket',

  // Subscription Page
  ORDER_SUMMARY: 'Order Summary',
  DISCOUNTED_AMOUNT: 'Discounted Amount',
  ACTUAL_AMOUNT: 'Actual Amount',
  CGST_AND_FEES: 'CGST and Fees',
  SGST_AND_FEES: 'SGST and Fees',
  IGST_AND_FEES: 'IGST and Fees',
  TOTAL: 'Total',
  PAYMENT_METHOD: 'Payment Method',
  NO_PAYMENT_METHOD_AVAILABLE: 'NO PAYMENT METHOD AVAILABLE',
  ROUND_OF_TO_NEARBY: 'Round of to nearby',
  BILLING_INFORMATION: 'Billing Information',
  NOTE_INVOICE: 'Note : If you want invoice copy kindly update your E-mail',
  SAVE_DETAILS: 'Save Details',
  UPDATE_FAILED: 'Update Failed',
  FREE: 'Free',
  PER_MONTH: 'Per Month ',
  YOU_CAN_UPGRADE: 'You Can Upgrade !',
  NOT_NOW: 'Not now',
  UPGRADE: 'Upgrade',
  UPGRADE_YOUR_PLAN: 'Upgrade your plan',
  TO_ACCESS: 'To access',
  PLEASE_UPGRADE_YOUR_SUBSCRIPTION: 'Please upgrade your subscription',
  RENEW: 'Renew',
  BUY_NOW: 'Buy now',
  WE_ARE_UNABLE_TO_PROCEED_WITH_THE_SELECTED_PLAN: 'We apologize, but your payment for the selected subscription plan cannot be processed at this time.',
  PLEASE_TRY_AGAIN_OR_CHOOSE_ANOTHER_ONE: 'Please try again later.',
  CONTACT_SUPPORT_TEAM: 'If the issue continues, contact our support team for help. Thank you.',
  CHOOSE_COUNTRY: 'Choose Country',
  ORGANIZATION_NAME: 'Organization Name',
  BILLING_ADDRESS: 'Billing Address',
  GST_NO: 'GST No',

  // Profile Modal
  ACCOUNT_SETTINGS: 'Account Settings',
  MY_INFO: 'My Info',
  PROFILE_SETTINGS: 'Profile Settings',
  CHANGE_PASSWORD: 'Change Password',
  ACCOUNT_ACTIVITY: 'Account Activity',
  FIRST_NAME: 'First Name',
  FIRST_NAME_IS_REQUIRED: 'FirstName is Required',
  LAST_NAME: 'Last Name',
  LAST_NAME_IS_REQUIRED: 'LastName is Required',
  EMAIL: 'Email',
  EMAIL_IS_REQUIRED: 'Email is Required',
  PHONE_NUMBER_IS_REQUIRED: 'Phone Number Is Required',
  PHONE_NUMBER_MUST_BE_6_TO_15_DIGITS: 'Phone Number Must Be 6 To 15 Digits',
  COUNTRY_IS_REQUIRED: 'Country is Required',
  STATE_LIST: 'State List',
  SELECT: 'Select',
  SELECT_STATE: 'Select State',
  OCCUPATION: 'Occupation',
  OCCUPATION_IS_REQUIRED: 'Occupation is Required',
  DATE_OF_BIRTH: 'Date Of Birth',
  DATE_OF_BIRTH_IS_REQUIRED: 'Date Of Birth is Required',
  SAVE: 'Save',
  DATE_FORMAT: 'Date Format',
  DATE_FORMAT_IS_REQUIRED: 'dateFormat is required',
  TIME_FORMAT: 'Time Format',
  TIME_FORMAT_IS_REQUIRED: 'timeFormat is required',
  LANGUAGE: 'Language',
  LANDING_PAGE: 'Landing Page',
  LANDING_PAGE_IS_REQUIRED: 'landingPage is required',
  TIME_ZONE: 'Time Zone',
  TIME_ZONE_IS_REQUIRED: 'Time Zone is required',
  SHOW_WEB_NOTIFICATION: 'Show Web Notification',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_NEW_PASSWORD: 'Confirm New Password',
  DEACTIVATE_ACCOUNT: 'DEACTIVATE ACCOUNT',
  DEACTIVATE_ACCOUNT_CONFIRMATION: 'Your account will be temporarily deactivated.',
  DEACTIVATE_YOUR_ACCOUNT: 'You will have the option to reactivate it later and reconnect with CoffeeWeb.',
  YES_DEACTIVATE: ' Yes, Deactivate',
  DELETE_ACCOUNT: 'DELETE ACCOUNT',
  DELETE_YOUR_ACCOUNT: 'Once you delete your account, it cannot be recovered. Please ensure that you want to proceed with this action.',
  YES_DELETE: 'Yes, Delete',
  ON: 'ON',
  OFF: 'OFF',
  PASSWORD_AND_CONFIRM_PASSWORD_SHOULD_BE_SAME: 'The password and confirm password entries do not match. Please ensure they are same.',
  PASSWORD_CHANGED_SUCCESSFULLY: 'Your password has been changed successfully.',
  UPDATED_SUCCESSFULLY: 'Updated successfully',
  EMAIL_ALREADY_EXISTS: 'Email already Exists!',
  SIGNING_EMAIL_EXIST: 'Selected email ID is already exists, kindly click on Continue to Login.',
  ENTER_ANOTHER_EMAIL: 'It looks like your email is already registered with Coffee Web. Please log in with your credentials or reset your password if you have forgotten it. ',
  DATA_UPDATED_SUCCESSFULLY: 'Your Details has been updated Successfully.',
  FAILED: 'Failed',
  ARE_YOU_SURE: 'Are you sure?',
  ACCOUNT_DELETED_SUCCESSFULLY: 'Your account has been Deleted successfully.',
  TRY_AGAIN_AFTER_SOMETIME: 'Try again after sometime!',
  ACCOUNT_DEACTIVATED: 'Your account has been Deactivated successfully.',
  OTP_IS_EXPIRED: 'OTP  is Expired',
  CLICK_ON_RESEND_TO_GET_NEW_OTP: 'Click on Resend to get new OTP',
  REQUEST_FOR_OTP: 'Request For OTP',

  // Coffee News Feed
  COFFEE_NEWS_FEEDS: 'Coffee News Feeds',
  BUY_SUBSCRIPTION: 'Buy Subscription',
  DAILY_FEED: 'Daily Feed',
  MY_FAVOURITE: ' My Favourite',
  SEARCH: 'Search',
  SEARCH_FOR_NEWS: 'Search For News',
  READ_MORE: ' Read More',
  LOADING: 'Loading...',
  SELECT_LANGUAGE: 'Select Language',
  ARE_YOU_SURE_YOU_WANT_TO_DISLIKE_THIS_NEWS: 'Are you sure you want to dislike this news?',
  NO_DATA_FOUND: 'No Data Found',
  SEE_MORE: 'See More',
  NEWS_FEED_API_FAILED: 'Unable to fetch feed, Please try again later.',
  NEWS_FEED_NO_DATA: 'No news feed is available',
  NEWS_FEED_ALL_NEWS_FETCHED: 'You have caught up with all the news.',
  NO_RESULTS_FOR_SEARCH: 'No results for your Search',

  // News Read More
  GO_BACK: 'Go Back',
  SHARE: 'Share',
  COFFEE_NEWS_DATA_DOWNLOAD: 'Coffee News Data Download ',
  DOWNLOAD_DOCUMENT: 'Download Document',
  VIEW_DOCUMENT: 'View Document',
  DUMMY_NEWS_TITLE: 'Welcome to CoffeeWeb',
  DUMMY_NEWS_TIME: '00:00',
  DUMMY_NEWS_DATE: 'Jan 01 2024',
  DUMMY_NEWS_AUTHOR: 'CoffeeWeb',

  // Share News Feed
  COPY: 'Copy',
  WHATS_APP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  LINKED_IN: 'LinkedIn',
  TWITTER: 'Twitter',
  TELEGRAM: 'Telegram',
  MAIL: 'Mail',
  SHARE_MODAL: 'Share with',
  SHARE_WITH_LINK: 'Or share with link',

  // Guest News Feed
  CONTENT_FORBIDDEN_MESSAGE: 'To access this page, you need to Login First',
  CONTENT_FORBIDDEN_MESSAGE_CONFIRMATION: 'Do you wish to proceed?',
  CURRENTLY_NEWS_FEED_NO_DATA: 'Currently No News Feed Available',

  // Coffee Quotes
  VOL: 'Volume',
  HIGH: 'High',
  LOW: 'Low',
  OPEN: 'Open',
  BID: 'Bid',
  ASK: 'Ask',
  OPEN_INT: 'Open Int',
  NET_HIGH: 'Net-High',
  NET_LOW: 'Net-Low',
  NET_CHG: 'Net Chg',
  PRE_CLOSE: 'Pre Close',
  BSIZE: 'B-size',
  ASIZE: 'A-size',
  OPTION_EXPIRY: 'Option Expiry',
  FIRST_NOTICE_DAY: 'First Notice Day',
  LET_S_HELP: 'Let’s help',
  LAST: 'Last',
  CHG: 'Chg',
  PLEASE_FILL_DIVIDER_NAME: 'Divider Name is empty. Please provide a value.',
  ROBUSTA_DIVIDER: 'Robusta Divider',
  ARABICA_DIVIDER: 'Arabica Divider',
  ROBUSTA: 'Robusta',
  ARABICA: 'Arabica',
  ADD: 'Add',
  GRADE: 'Grade',
  NET_PRICE: 'Net Price',
  OUT_RATE: 'Out Rate',
  DIFFERENTIAL: 'Differential',
  TIME: 'Time',
  DATE: 'Date',
  TRENDING_NEWS: 'News',
  ROBUSTA_CONTRACTS: 'Robusta Contracts',
  ARABICA_CONTRACTS: 'Arabica Contracts',
  CONTRACT_TYPE: 'Contract Type',
  FX: 'FX',
  FX_PRE_DIS: 'FX-Pre/Dis',
  FX_NET: 'FX Net',
  GROSS_PRICE: 'Gross Price',
  PROFIT: 'Profit/Exp',
  DELETE: 'Delete',
  CONVTO: 'Conv To',
  WEEK_HIGH: 'Weeks High',
  WEEK_LOW: 'Weeks Low',
  TERMINALS: 'Terminals',
  VOLUME: 'Volume',
  B_SIZE: 'B-Size',
  A_SIZE: 'A-Size',
  COMPARED_TO_PREV_DAY: 'Compared to prev day',
  ROBUSTA_WORKBOOK: 'Robusta Workbooks',
  ARABICA_WORKBOOK: 'Arabica Workbooks',
  CHANGES_UPDATED_SUCCESSFULLY: 'Changes has been updated Successfully.',
  YES_DELETE_IT: 'Yes, delete it!',
  WORKBOOK_DELETED_SUCCESSFULLY: 'Workbook has been deleted Successfully.',
  YOUR_MARKET_WORKBOOK_HAS_BEEN_REMAINS_SAFE: 'Your MarketWorkbook has been remains safe!',
  DELETE_FAILURE: 'Delete Failure!',
  FAILED_TO_GET_DATA: 'Failed to get data!',
  SAVE_ALL_CHANGES: 'Save All Changes',
  SELECT_ALL_ROBUSTA_WORKBOOK: 'Select All Robusta Workbooks',
  SELECT_ALL_ARABICA_WORKBOOK: 'Select All Arabica Workbooks',
  DELETE_SELECTED_WORKBOOK_DATA: 'Delete Selected Workbook Data',
  THIS_DATA_IS_FOR_TESTING_PURPOSE: 'This data is for testing purpose',
  ARBITRAGE: 'Arbitrage',
  VS: 'VS',
  IN: 'IN',
  IN_USD: 'in USD',
  IN_CENTS: 'in Cents',
  TERMINAL_ARBITRAGE_HISTORY: 'Terminal Arbitrage History',
  TERMINAL_DIFFERENCE_HISTORY: 'Terminal Difference History',
  DIFFERENCE: 'Difference',
  ROBUSTA_VS_ARABICA: 'Robusta VS Arabica',
  ARABICA_VS_ROBUSTA: 'Arabica VS Robusta',
  TERMINAL_DIFFERENCE_HISTORY: 'Terminal Difference History',
  TERMINAL_DATA: 'Terminal Data',
  TERMINAL_HISTORY: 'Terminal History',
  TERMINAL_DIFFERENCE: 'Terminal Difference',
  TERMINAL_CHART: 'Terminal Chart',
  LONDON: 'London',
  NEW_YORK: 'New York',
  REGULAR_SUBSCRIPTION_UPDATE: 'Upgrade to Platinum For an Exclusive Global Coffee Insights and Knowledge!',
  GOLD_SUBSCRIPTION_UPDATE: '"Sometimes Upgrade Brings a Lot of Gains"',
  PLATINUM_SUBSCRIPTION_UPDATE: 'We Serve all the Data for You in the First Place.',
  COMING_SOON: 'Coming Soon...',
  ADD_CONTRACT: 'Add Contract',

  // Global Differentials
  GLOBAL_DIFFERENTIALS: 'Global Differentials',
  SELECT_ORIGIN: 'Select Origin',
  CROP_YEAR: 'Crop Year',
  UPDATED_ON: 'Updated On',
  PRICES_BASED_ON_FOB: 'Prices Based On FOB',
  QUALITY: 'Quality',
  DIFFERENTIALS: 'Differentials',
  QUALITY_STANDARDS: 'Quality Standards',
  REMARKS: 'Remarks',
  DELIVERY_PORT: 'Delivery Port',
  FOREX_LEVEL: 'Forex Level',
  SIZE: 'Size',
  DIFFERENTIALS_BASED_ON_FOB: 'Differentials based on FOB',
  SL: 'SL',
  SCREEN: 'Screen',
  BETWEEN: 'Between',
  FOB_PORT: 'FOB Port',
  MOISTURE: 'Moisture',
  SCREEN_RETENTION: 'Screen Retention',
  DEFECTS: 'Defects',
  NO_DATA_FOUND_FOR_THIS_COUNTRY: 'No Data Found For This Country!',
  RANGE_STARTS: 'Range Starts',
  RANGE_ENDS: 'Range Ends',
  QUALITY_NAME: 'Quality Name',
  HISTORICAL_DATA: 'Historical Data',
  TERMINAL: 'Terminal',
  TERMINAL_LEVEL: 'Terminal Level',
  TO_ACCESS_MORE_FEATURE_CREATE_ACCOUNT: 'To Access More Features and Information, Kindly Login or Create Account.',
  TO_ACCESS_MORE_FEATURE_LOGIN: 'To access more features, please create an account or Log in.',
  TO_ACCESS_GLOBAL_DIFFERENTIAL_FEATURE: 'This Data is Available Exclusively for Platinum Users.',
  THIS_DATA_IS_AVAILABLE_EXCLUSIVELY_FOR_GOLD_AND_PLATINUM_USERS: 'This Data is Available Exclusively for Gold and Platinum Users.',
  TO_ACCESS_INFO_GLOBAL_DIFFERENTIAL: 'To Access the Information Please Login or Create Account. This Data is Available Exclusively For Platinum Users.',
  PREVIOUS_DATA: 'Previous Data',

  // Global raw coffee Price
  GLOBAL_RAW_COFFEE_PRICES: 'Global Raw Coffee Prices',
  GLOBAL_COFFEE_PRICES: 'Global Coffee Prices',
  SELECT_CURRENCY: 'Select Currency',
  PRICE_PER_BAG: 'Price Per Bag',
  PRICES: 'Prices',
  FOREX_TYPE: 'Forex Type',
  FOREX_VALUE: 'Forex Value',
  STANDARDS: 'Standards',
  BAG_WEIGHT: 'Bag Weight',
  MC: 'MC',
  OUT_TURN: 'Out Turn',
  RAW_COFFEES: ' Raw Coffees',

  // Notification
  NO_NOTIFICATIONS: 'No Notifications',
  NO_MORE_NOTIFICATIONS: 'No more notifications.',
  NOTIFICATIONS: 'Notifications',
  MARK_ALL_AS_READ: 'Mark All As Read',
  SEE_ALL_NOTIFICATIONS: 'See All Notifications',
  CONFORMATION_NOTIFICATION: 'Are you sure you want to mark all notification as read?',
  MARK_AS_READ: 'Mark as Read',
  UNSELECT_ALL: 'Unselect All',
  SELECT_ALL: 'Select All',

  // Error Boundary
  YOUR_SESSION_HAS_EXPIRED: 'Your Session Has Expired',
  PLEASE_LOG_IN_AGAIN_TO_CONTINUE_USING_THE_APP: 'Please Log In Again To Continue Using The App',
  RE_LOGIN: 'Re-Login',
  TRY_AGAIN: 'Try Again',

  // Footer
  COPYRIGHT: '© Copyright ',
  COFFEE_WEB: 'CoffeeWeb Technologies Private Limited.',
  ALL_RIGHTS_RESERVED: 'All Rights Reserved ',

  // About Us
  ABOUT_US: 'About Us',
  ABOUT_GLOBAL_COFFEE_INDUSTRY: 'This Platform provides end-to-end information about the Global Coffee Industry.',
  COFFEE: 'Coffee',
  ABOUT_COFFEE: 'The second most traded commodity globally involving nearly 196 countries!',
  COUNTRIES_ABOUT_COFFEE: `Coffee has a rich history spanning centuries, and it's enjoyed in 196 nations worldwide, these countries engage both in it's consumption and production.`,
  COUNTRIES_ARE_INVOLVED: 'these countries are involved as consumers and producers.',
  GLOBAL_MARKET: 'This thriving global market underscores the universal appeal and economic significance of coffee on a global scale.',
  COFFEEWEB: 'CoffeeWeb',
  ABOUT_COFFEE_SOURCE: 'Your Exclusive Source for Coffee Insights!',
  ABOUT_COFFEE_PREMIER_SOURCE: 'CoffeeWeb stands as your premier source for exclusive insights into the ever-evolving coffee industry.',
  ABOUT_COFFEE_WEB_SITE_FEATURE: 'From production and consumption data to Coffee Prices, Weather Updates, Differentials, and Freight Rates.',
  ABOUT_COFFEE_WEB_SITE_INFORMATION: 'We offer a comprehensive platform to keep you informed. Stay ahead of the curve with the latest news and trends in coffee-producing and consuming nations, making CoffeeWeb your go-to hub for all things coffee.',
  BENEFICIAL_FOR_ALL_COFFEE_INDUSTRY_STAKEHOLDERS: 'Beneficial for All Coffee Industry Stakeholders!',
  BENEFICIAL_FOR_ALL_COFFEE: 'CoffeeWeb caters to the diverse needs of coffee industry Professionals, including Exporters, Importers, Roasters, Processors, Traders, Growers, and anyone involved in the coffee sector.',
  OUR_COMPREHENSIVE_PLATFORM_PROVIDES: ' Our comprehensive platform provides valuable information and resources tailored to your specific interests and requirements.',
  VISION_AND_MISSION: 'Vision and Mission',
  VISION: 'Vision',
  MISSION: 'Mission',
  ABOUT_VISION: '"At CoffeeWeb, we envision a global community connected through the shared love for coffee. Our vision is to be the unparalleled source of knowledge, fostering transparency and collaboration in the dynamic and ever-evolving coffee industry."',
  ABOUT_MISSION: '"Our mission at CoffeeWeb is to provide end-to-end information, becoming the go-to platform for comprehensive insights into the world of coffee. We are dedicated to delivering accurate, timely, and valuable data, empowering stakeholders across the coffee supply chain to make informed decisions and drive positive change in the industry."',
  COFFEE_MAP: 'Coffee Map',
  ANALOG_CLOCK: 'Analog Clock',
  DIGITAL_CLOCK: 'Digital Clock',
  NO_RESULT_FOUND: ' No results found',
  SEARCH_COUNTRY: 'Search Country',
  SET_FAV: 'Set Favourite',
  AVAILABLE_COUNTRIES: 'Available Countries',
  SELECTED_COUNTRIES: 'Selected Countries',
  SEARCH_BY_NAME: 'search by country name',
  CLOCK_SETTINGS: 'Clock Settings',
  FAVORITE_CLOCK: 'Make Clock Favourite',
  SELECT_TYPE: 'Clock Display Type',
  ERROR_MESSAGE: 'Please Select At Least One Country',
  SELECT_TIME_FORMAT: 'Select Time Format',
  FETCHING: 'Fetching Data...',

  // Payment History
  PAYMENT_HISTORY: 'PAYMENT HISTORY',
  PURCHASE_DATE: 'Purchase Date',
  SUBSCRIPTION_TYPE: 'Subscription Type',
  EXPIRY_DATE: 'Expiry Date',
  AMOUNT: 'Amount',
  DO_YOU_WANT_TO_CHANGE_THE_TIME_FORMAT_PERMANENTLY: 'Do you wish to change the time format?',
  // Payment Status
  PAYMENT_SUCCESS: 'Your payment has been processed successfully.',
  WE_ARE_UPGRADING_KINDLY_WAIT: 'We are upgrading, kindly wait',
  PLEASE_TRY_AGAIN: 'Please Try Again!',
  PAYMENT_FAILED: ' Payment Failed',
  PAYMENT_CANCELED: 'Payment has been cancelled!',

  // Dialog Modal - Error Handler
  NO_INTERNET_CONNECTION: 'No Internet Connection',
  PLEASE_CHECK_YOUR_CONNECTION: 'Please Check Your Connection And Try Again',
  SESSION_EXPIRED: 'Your Session Has Expired',
  PLEASE_LOGIN: 'Please Login Again',
  SERVER_ERROR: 'Server Error',
  DATA_ALREADY_EXISTS: 'Data Already Exists',
  PLEASE_ENTER_UNIQUE_DATA: 'Please Enter Unique Data',
  NO_COUNTRY_AVAILABLE: 'No Country Available',

  // Dialog Modal - Success Error
  CONFIRM: 'Confirm',
  ERROR_TITLE: 'Error',
  EMAIL_EXIST: 'Email Already Exists',
  DEACTIVATE: 'Deactivate',
  DEFAULT_SUCCESS_MESSAGE: 'The operation was completed successfully.',
  DEFAULT_ERROR_MESSAGE: 'An error occurred. Please try again.',
  DEFAULT_CONFIRMATION_MESSAGE: 'Are you sure you want to proceed?',
  THANK_YOU: 'Thank You.',
  DEACTIVATE_USER_ACCOUNT: 'Deactivate Account',
  DELETE_USER_ACCOUNT: 'Delete Account',
  LOGOUT_ACCOUNT: 'Logout',
  CHANGE_TIME_FORMAT: 'Change time format',
  DELETE_RECORD: 'Delete Record',
  PAYMENT_DEDUCTED_MESSAGE: 'If your payment has been deducted from your account, it will be reverted soon.',
  PAYMENT_CANCELED_MESSAGE: 'It seems you have Cancelled the Payment at your end.Please proceed again, or if the issue is at Payment gateway side please try again in sometime.',
  // Coffee Reports
  COFFEE_REPORTS: 'Coffee Reports',
  SELECT_REPORT: 'Select Report',
  REPORT_DATE: 'DATE',
  CERTIFIED_STOCKS: 'CERTIFIED STOCKS',
  COMPARE_TO_PRE_DAY: 'COMPARE TO PRE DAY',
  SUSPENDED: 'SUSPENDED',
  TOTAL_CERTIFIED_STOCKS: 'Total Certified Stocks',
  ALL_COUNTRY_CERTIFIED_STOCK: 'All Country Certified Stocks',
  COUNTRYWISE_CERTIFIED_STOCK: 'Countrywise Certified Stocks',
  PORT_WISE_CERTIFIED_STOCK: 'Portwise Certified Stocks',
  SELECT_MARKET: 'Select Market Type',
  MT_LOTS: 'MT/LOTS',
  SL_NO: 'SL NO',
  ORIGIN: 'ORIGIN',
  VALID: 'VALID',
  NON_TENDERABLE: 'NON TENDERABLE',
  TOTAL_VALUE: 'TOTAL',
  CERTIFIED_STOCKS_REPORTS: 'TOTAL CERTIFIED STOCKS',
  PORT_WISE_CERTIFIED_STOCKS: 'PORT WISE CERTIFIED STOCKS',
  WEEKLY_WISE_COT_REPORTS: 'Weeklywise Report',
  LOTWISE_COMPARISON: 'Lotwise Report',
  TRADERWISE_COMPARISON: 'Traderwise Report',
  PENDING_GRADING_REPORT: 'PENDING GRADING REPORT',
  ANTWERP_COLUMN: 'ANTWERP',
  HAMBURG_BREMEN: 'HAMBURG/BREMEN',
  HOUSTON_COLUMN: 'HOUSTON',
  NEW_ORLEANS: 'NEW ORLEANS',
  NEW_YORK_COLUMN: 'NEW YORK',
  VIRGINIA_COLUMN: 'VIRGINIA',
  COUNTRY_NAME: 'COUNTRY NAME',
  BARCELONA_COLUMN: 'BARCELONA',
  MIAMI_COLUMN: 'MIAMI',

  // Country Wise Exports Report
  MONTH_WISE_REPORT: 'Monthwise Report',
  MT_MILLION_BAGS: 'MT/Million Bags',
  YEAR_2024: 'Year : 2024',
  MONTH: 'MONTH',
  ARABICA_COFFEE_REPORTS: 'ARABICA',
  ROBUSTA_COFFEE_REPORTS: 'ROBUSTA',
  SOLUBLE: 'SOLUBLE',
  ROASTED: 'ROASTED',
  TOTAL_COMPARISON_DATA: 'Total Comparison Data',
  MONTH_COMPARISON_DATA: 'Month Comparison Data',
  NO_DATA_FOR_SELECTED_YEAR: 'No data available for the selected year or the previous year.',
  NO_DATA_FOR_PREVIOUS_YEAR: 'No data available for the selected year or the previous year.',
  NO_DATA: 'No Data',
  PERCENTAGE_WISE: '% WISE',

  // Commitment of Traders Report
  OPEN_INTEREST: 'Open Interest',
  NET_CHANGE: 'Net Change',
  PERCENTAGE_OPEN_INTEREST: '% of Open Interest',
  TOTAL_TRADERS: 'Total Traders',
  LONG: 'LONG',
  SHORT: 'SHORT',
  NET: 'NET',
  SPREADING: 'SPREADING',
  PERCENTAGE_LONG: '% LONG',
  PERCENTAGE_SHORT: '% SHORT',
  DATES_UPPERCASE: 'DATES',
  TERMINAL_LEVEL_UPPERCASE: 'TERMINAL LEVEL',
  WAREHOUSES_TITLE: 'WAREHOUSE',
  DAILY_GRADING_SUMMARY: 'DAILY GRADING SUMMARY',

  // USDA Coffee Reports
  USDA_COFFEE_REPORTS: 'USDA Coffee Reports',
  WILL_BE_PUBLISHED_ON: 'Will be Published on:',
  READ_LESS: ' Read Less',
  LINK_TO_REPORT: 'Link to Report:',
  NO_REPORT_AVAILABLE: 'No Report Available',

  // SEO content
  ABOUT_COFFEE_WEB_TITLE: 'About CoffeeWeb | Shaping the Future of the Global Coffee Industry  | CoffeeWeb',
  ABOUT_COFFEE_WEB_DESCRIPTION: 'CoffeeWeb is committed to delivering expert insights, data, and trends for the global coffee industry. Learn about our mission to support professionals with market analysis, production updates, and sustainability practices.',
  ABOUT_COFFEE_WEB_KEYWORDS: 'about CoffeeWeb, global coffee industry, coffee insights, coffee trends, coffee subscriptions, coffee market analysis, CoffeeWeb mission',
  ABOUT_COFFEE_WEB_H1_CONTENT: 'About CoffeeWeb – Your Trusted Source for Coffee Industry Insights & Subscriptions',

  COFFEE_NEWS_FEED_TITLE: 'Coffee News Feeds | Latest Updates from the Global Coffee Industry | CoffeeWeb',
  COFFEE_NEWS_FEED_DESCRIPTION: 'Stay updated with CoffeeWeb’s curated coffee news feeds, providing the latest trends, insights, and developments in the global coffee industry. Subscribe for daily updates on coffee production, market shifts, and sustainability efforts.',
  COFFEE_NEWS_FEED_KEYWORDS: 'coffee news, coffee industry updates, global coffee trends, coffee market news, coffee production, coffee sustainability, coffee subscriptions, CoffeeWeb news',
  COFFEE_NEWS_FEED_H1_CONTENT: 'Latest Coffee Industry News & Trends – Stay Informed with CoffeeWeb Feeds',

  COFFEE_QUOTES_TITLE: 'Coffee Quotes | Global Coffee Industry Insights, Trends, and Market Data | CoffeeWeb',
  COFFEE_QUOTES_DESCRIPTION: 'CoffeeWeb delivers in-depth insights, trends, and comprehensive market data on the global coffee industry.Stay informed about coffee production, sustainability, and market developments worldwide.',
  COFFEE_QUOTES_KEYWORDS: ' global coffee industry, coffee insights, coffee market trends, coffee production data, coffee market analysis, sustainability in coffee, Coffee Subscriptions, CoffeeWeb, Coffee News, Coffee Prices',
  COFFEE_QUOTES_H1_CONTENT: 'Explore Global Coffee Industry Insights, Trends, and Market Data with CoffeeWeb',

  COFFEE_REPORTS_TITLE: 'Coffee Reports | In-Depth Analysis and Insights on the Coffee Market | CoffeeWeb',
  COFFEE_REPORTS_DESCRIPTION: 'Access comprehensive coffee reports at CoffeeWeb. Stay informed with in-depth analyses, market trends, and valuable insights on coffee production, pricing, and industry developments.',
  COFFEE_REPORTS_KEYWORDS: 'coffee reports, coffee market analysis, coffee industry insights, coffee production, coffee pricing, market trends, CoffeeWeb reports',
  COFFEE_REPORTS_H1_CONTENT: 'Comprehensive Coffee Reports: In-Depth Analysis and Market Insights',

  GLOBAL_DIFFERENTIAL_TITLE: 'Global Differentials  | Price Variations in the Coffee Market | CoffeeWeb',
  GLOBAL_DIFFERENTIAL_DESCRIPTION: 'Explore CoffeeWeb’s analysis of global coffee differentials. Learn about price variations in the coffee market and how they impact producers and consumers. Stay informed with the latest data and insights.',
  GLOBAL_DIFFERENTIAL_KEYWORDS: 'coffee differentials, global coffee prices, coffee market analysis, coffee pricing, coffee market trends, CoffeeWeb differentials',
  GLOBAL_DIFFERENTIAL_H1_CONTENT: 'Understanding Global Coffee Differentials: Price Variations and Market Insights',

  SUPPORT_REQUEST_TITLE: 'Support | CoffeeWeb Help Center for Global Coffee Industry Insights | CoffeeWeb',
  SUPPORT_REQUEST_DESCRIPTION: 'Need assistance? Visit CoffeeWeb’s Support page for help with our services, resources, and insights into the global coffee industry. We are here to assist you with all your inquiries.',
  SUPPORT_REQUEST_KEYWORDS: 'CoffeeWeb support, coffee industry assistance, help center, coffee resources, customer support, coffee insights',
  SUPPORT_REQUEST_H1_CONTENT: 'Welcome to CoffeeWeb Support – Your Help Center for Coffee Industry Insights',

  GLOBAL_RAW_COFFEE_PRICES_TITLE: 'Global Raw Coffee Prices | CoffeeWeb',
  GLOBAL_RAW_COFFEE_PRICES_DESCRIPTION: 'Stay updated with the latest global raw coffee prices, market trends, and insights. CoffeeWeb offers comprehensive data on coffee industry prices, production, and trading to help you make informed decisions in the coffee market.',
  GLOBAL_RAW_COFFEE_PRICES_KEYWORDS: 'global coffee prices, raw coffee market, coffee price trends, coffee industry insights, coffee market analysis, coffee trading data, coffee price fluctuations, coffee supply and demand, coffee industry news',
  GLOBAL_RAW_COFFEE_H1_CONTENT: 'Global Raw Coffee Prices and Market Insights',

  LOG_IN_TITLE: 'Log In | CoffeeWeb',
  SIGN_IN_TITLE: 'Sign In | CoffeeWeb',

  DEFAULT_COFFEE_WEB_TITLE: 'CoffeeWeb'
}

export default EN_LANGUAGES
